<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import BtnAddProductReason from "@/feature/annual_report/presentation/views/components/btn_add_product_reason.vue";
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import ReasonsMultiSelect from "@/feature/annual_report_v2/presentation/components/ReasonsMultiSelect.vue";

const data = [{
  producto: "",
  contador_producto: null,
  descripcion_producto: "",
  descripcion_motivo: "",
  motivo: [],
  contador_motivo: null
}]

export default {
  name: "ProductReasonsField",
  components: { ReasonsMultiSelect, BtnAddProductReason, Accordion },
  props: {
    urlGetCounter: {
      type: String,
      default: undefined
    },
    labelTotalCounter: {
      type: String,
      default: undefined
    },
    labelInstanceCounter: {
      type: String,
      default: undefined
    },
    hideInstanceCounter: {
      type: Boolean,
      default: false
    },
    hideTopInputs: {
      type: Boolean,
      default: false
    },
    labelProductCounter: {
      type: String,
      default: undefined
    },
    labelReasonsCounter: {
      type: String,
      default: undefined
    },
    totalCounter: {
      type: [Number, String],
      default: "",
    },
    products_reasons: {
      type: Array,
      default: () => JSON.parse(JSON.stringify(data))
    },
    soloPreview: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    instanceCounter: "0",
    showDescriptionProduct: false,
    showDescriptionReasons: false,
    optionsReasons: [],
    optionsProducts: [],
    selectedReasons: [],
  }),
  created() {
    this.initDataProductReasons(3)
    if (this.products_reasons.length === 0) {
      this.productReasonsModel = JSON.parse(JSON.stringify(data))
    }
  },
  methods: {
    async initDataProductReasons(value) {
      if (value) {
        this.optionsReasons = await this.$store.dispatch(
          "form/loadReasonsOptions",
          { company: value }
        );
        this.optionsProducts = await this.$store.dispatch(
          "form/loadProductsOptions",
          { company: value }
        );
        const otherOption = {
          "id": 0,
          "name": "OTROS"
        }
        this.optionsProducts.push(otherOption);
      }
    },
    onChangeReasons(values, item) {
      item.motivo = values.map((val) => val.id.toString())
      console.log(item.motivo, item.motivo.includes('99'), !!item.motivo.includes('99'))
      this.showDescriptionReasons = !!item.motivo.includes('99');
    },
    onAdd() {
      this.productReasonsModel.push({ ...JSON.parse(JSON.stringify(data[0])) })
    },
    onDelete(index) {
      if(!this.soloPreview){
        this.productReasonsModel.splice(index, 1)
      }
    },
    async onBlurTotalCounter(evt, val) {
      if (!this.hideInstanceCounter && val) {
        const response = await this.getCounters();
        this.instanceCounter = String(response.complaint_count)
        if (this.totalCounterModel !== this.instanceCounter) {
          this.$notification.close("counter");
          this.$notification.warning({
            key: 'counter',
            message: "No coincide el número registrado por el DCF con el SMART SUPERVISION"
          });
        }
      }
    },
    async getCounters() {
      let json = {
        "entity_code": 3,
        "products": [],
        "reasons": [],
      }
      let data = await AnnualReportServices.getCounters(json, this.urlGetCounter);
      if (!data.isSuccess) {
        this.$notification.close("request");
        this.$notification.warning({
          key: 'request',
          message: data.message
        });
      }
      return data.data;
    },
  },
  computed: {
    productReasonsModel: {
      get() {
        return this.products_reasons
      },
      set(value) {
        this.$emit("update:products_reasons", value)
      }
    },
    totalCounterModel: {
      get() {
        return this.totalCounter
      },
      set(value) {
        this.$emit("update:totalCounter", value)
      }
    },
  },
}
</script>

<template>
  <div class="w-full">
    <div class="grid grid-cols-2 gap-x-8 mb-6" v-if="!hideTopInputs">
      <BaseInput type="number" numeric :label="labelTotalCounter" :required="true" @blur="onBlurTotalCounter"
        v-model="totalCounterModel" :disabled="soloPreview"/>

      <BaseInput v-if="!hideInstanceCounter" type="number" numeric :label="labelInstanceCounter" :disabled="true"
        :value="instanceCounter"/>
    </div>
    <div class="w-100 mb-6">
      <Accordion v-for="(item, index) in productReasonsModel" :key="index" :open="false" :disable="false"
        class="w-100 mt-4" header="Producto y motivo" :is-delete="index > 0" @deletecomponent="onDelete(index)">
        <div class="w-full grid grid-cols-2 gap-y-6 gap-x-10 justify-end items-end">
          <BaseSelect label="Producto" :selectOptions="optionsProducts" v-model="item.producto" :disabled="soloPreview"/>
          <BaseInput type="number" numeric :label="labelProductCounter" v-model="item.contador_producto" :disabled="soloPreview"/>
          <BaseTextarea v-if="item.producto == '0'" class="col-start-1 col-span-2" label="Descripción" maxlength="100"
            v-model="item.descripcion_producto" :disabled="soloPreview"/>

          <ReasonsMultiSelect title="Motivo" label="name" :data="optionsReasons" :defaultValues="item.motivo"
            @update-motivo="(newMotivos) => item.motivo = newMotivos" :soloPreview="soloPreview" />
          <BaseInput :type="'number'" :numeric="true" :label="labelReasonsCounter" v-model="item.contador_motivo" :disabled="soloPreview"/>
          <BaseTextarea v-if="!!item.motivo.includes('99')" class="col-start-1 col-span-2" label="Descripción"
            maxlength="100" v-model="item.descripcion_motivo" :disabled="soloPreview"/>
        </div>
      </Accordion>
      <BtnAddProductReason v-if="!soloPreview" @click="onAdd" />
    </div>
  </div>
</template>

<style scoped></style>