<template>
    <div>

        <Accordion :open="openAccordions.acc_1" :disable="false" key="astep1" class="px-0"
            header="1. De la función de atención y resolución de quejas">

            <div class="w-full">

                <BaseRadio ref="ar_type_11" name="ar_type_11"
                    label="¿El DCF recibió quejas de los consumidores financieros contra la entidad en el período reportado?"
                    @onChangeValueGetValue="(value) => onChangeRadio(value, 'acc_11')" :radioOptions="options.oYesOrNot"
                    required="true" class="col-span-2"
                    v-model="initialData.step_1_1.DCF_recibio_quejas_consumidores_contra_entidad_período_reportado"
                    :disabled="soloPreview" />

                <Step11 v-if="openAccordions.acc_11" :open="openAccordions.acc_11" :mainData="initialData" :soloPreview="soloPreview" />

                <Step12 :open="openAccordions.acc_12" :mainData="initialData" :soloPreview="soloPreview" />

                <Step13 :open="openAccordions.acc_13" :mainData="initialData" :soloPreview="soloPreview" />

            </div>

        </Accordion>
    </div>
</template>


<script>
import Step11 from "../components/step1_detail/step_1.1.vue";
import Step12 from "../components/step1_detail/step_1.2.vue";
import Step13 from "../components/step1_detail/step_1.3.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";


export default {
    components: {
        Accordion,
        Step11,
        Step12,
        Step13,
    },
    props: {
        soloPreview: {
            type: Boolean,
            default: false
        },
        initialData: {}
    },
    data() {
        return {
            hasChanged: true,
            openAccordions: {
                acc_1: true,
                acc_11: false,
                acc_12: true,
                acc_13: true,
            },
            options: {
                oYesOrNot: [
                    { id: 1, name: "Sí", boolValue: true },
                    { id: 2, name: "No", boolValue: false },
                ],
            }
        }
    },
    created() {
        this.mainData = {
            ...this.mainData,
            ...this.initialData,
        }
        
        if (this.soloPreview) {
            this.openAccordions.acc_11 = true;
        }
    },
    methods: {
        onChangeRadio(value, key) {
            this.openAccordions[key] = value.boolValue;
        },
        notifyChanges() {
            let error = false;
            // Se implementa lógica antes de guardar la info, en caso de ser necesario
            return { error: error, data: this.mainData };
        },
    },
    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>