<template>
  <div>
    <!--  **************************************  START STEP 3  *********************************************  -->
    <Accordion
      :open="openAccordions.acc_3"
      :disable="false"
      key="astep1"
      class="px-0"
      header="3. De la función de la conciliación"
    >
      <div
        class="w-full grid grid-cols-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
      >
        <BaseTextarea
          v-model="mainData.descripcion"
          :disabled="soloPreview"
          class="col-start-1 col-span-4"
          ref="describe"
          id="describe"
          label="Describa la infraestructura con la que cuenta la DCF para el desarrollo de la función de conciliación"
          placeholder="Mensaje"
          maxlength="500"
        />

        <BaseSelect
          v-model="mainData.optInfraestructura"
          :disabled="soloPreview"
          @change="onChangeSelectInfra"
          :getObjectValue="true"
          ref="opt_infraestructura"
          class="col-start-1 col-span-2"
          label="Confirme si la infraestructura es propia de la DCF o por convenio con un tercero"
          :selectOptions="options.oInfra"
        />

        <BaseRadio
          v-model="mainData.dcf_recibio_solicitudes"
          :disabled="soloPreview"
          ref="opt_dcf_recibio_solicitudes"
          name="opt_dcf_recibio_solicitudes"
          label="¿La DCF recibió solicitudes de conciliación en el período reportado?"
          :radioOptions="options.oYesOrNot"
          required="true"
          class="col-start-1 col-span-2"
        />

        <ProductReasonsField
          v-if="mainData.dcf_recibio_solicitudes"
          :soloPreview="soloPreview"
          :uniqueIdentity="'paso_3'"
          class="mt-10 mb-10 col-start-1 col-span-4"
          :total-counter.sync="mainData.numero_total_solicitudes"
          :products_reasons.sync="mainData.productos_motivos"
          :hideInstanceCounter="true"
          label-product-counter="Número de solicitudes de conciliación recibidas en el período según producto seleccionado"
          label-reasons-counter="Número de solicitudes de conciliación recibidas en el período según motivo seleccionado"
          label-total-counter="Número total de solicitudes de conciliación recibidas en el período"
        />

        <!--  **************************************  START STEP 3.1  *********************************************  -->
        <Accordion
          v-if="mainData.dcf_recibio_solicitudes"
          :open="openAccordions.acc_31"
          :disable="disableAccordions.disable_31"
          key="astep1"
          class="px-0 col-start-1 col-span-4"
          header="3.1 Solicitudes inadmitidas"
        >
          <BaseRadio
            v-model="mainData.paso_3_1.dcf_inadmitio_solicitudes"
            :disabled="soloPreview"
            ref="opt_dcf_inadmitio_solicitudes"
            name="opt_dcf_inadmitio_solicitudes"
            label="¿La DCF inadmitió solicitudes de conciliación en el período reportado?"
            :radioOptions="options.oYesOrNot"
            required="true"
            class="mb-8"
          />

          <div
            v-if="mainData.paso_3_1.dcf_inadmitio_solicitudes"
            class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
          >
            <BaseNumber
              v-model="mainData.paso_3_1.numero_total_solicitudes"
              :disabled="soloPreview"
              ref="num_31_numero_total_solicitudes"
              label="Número total de solicitudes de concilación inadmitidas"
              class="col-start-1 col-span-2"
              placeholder="Ej: 123"
            />

            <BaseCheckboxVertical
              :disabled="soloPreview"
              class="col-start-1 col-span-4"
              label="Justificación de la inadmisión por parte del DCF"
              :data="options.oJustInadmision"
              :value="mainData.paso_3_1.justificacion_inadmision.ids"
              @notify-changes="onChangeSelectJustInadmision"
            />

            <div class="col-span-2"></div>
            <a-card
              v-if="isJustInadActive('1')"
              size="small"
              class="text-left col-span-4"
              title="1) Causal legal de exclusión de competencia"
            >
              <a-button
                slot="extra"
                type="link"
                @click="
                  openAccordions.showJustInad1 = !openAccordions.showJustInad1
                "
              >
                Ver detalles
                <a-icon :type="openAccordions.showJustInad1 ? 'up' : 'down'" />
              </a-button>
              <div v-if="openAccordions.showJustInad1">
                <BaseNumber
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision.causal_legal
                      .numero_total_solicitudes
                  "
                  :disabled="soloPreview"
                  ref="num_31_numero_total_solicitudes"
                  label="Número total de solicitudes de conciliación inadmitidas asociadas a la justificación seleccionada."
                  placeholder="Ej: 123"
                />

                <a-collapse class="mt-6">
                  <a-collapse-panel key="1" header="Causales">
                    <p
                      slot="extra"
                      class="text-opacity-80"
                      :class="
                        mainData.paso_3_1.justificacion_inadmision.causal_legal
                          .numero_total_solicitudes != totalCausales
                          ? 'text-red-500'
                          : 'text-green-500'
                      "
                    >
                      Total: {{ totalCausales }}
                      {{
                        mainData.paso_3_1.justificacion_inadmision.causal_legal
                          .numero_total_solicitudes != totalCausales
                          ? "(No coinciden los totales)"
                          : ""
                      }}
                    </p>
                    <a-table
                      size="small"
                      :pagination="false"
                      :row-selection="rowSelection"
                      :columns="columns"
                      :data-source="options.oTipoJustInCausaLegal"
                    >
                      <template slot="labelName" slot-scope="text, record">
                        <a>{{ text }}</a>
                      </template>
                      <template
                        class="p-0"
                        slot="count"
                        slot-scope="text, record"
                      >
                        <BaseNumber
                          v-if="isRowSelected(record.id)"
                          :disabled="soloPreview"
                          :value="valueCausal(record.id)"
                          @notify-change="updateDetail(record.id, $event)"
                          label="Digita una cantidad"
                          ref="num_31_numero_total_solicitudes"
                          class="col-start-1 col-span-2 mt-0 pt-0"
                          placeholder="Ej: 123"
                          :mt_disabled="true"
                        />
                      </template>
                    </a-table>
                  </a-collapse-panel>
                </a-collapse>

                <BaseRadio
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision.causal_legal
                      .aplica_producto_motivo
                  "
                  :disabled="soloPreview"
                  ref="opt_causal_legal_aplica_producto_motivo"
                  class="col-start-1 col-span-2"
                  label="¿Aplica producto y motivo?"
                  :radioOptions="options.oApplyProductReason"
                />

                <BaseTextarea
                  v-if="causalLegalIncludeOtra()"
                  :disabled="soloPreview"
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision.causal_legal
                      .otra_descripcion
                  "
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Descripción"
                  placeholder="Mensaje"
                  maxlength="50"
                />

                <ProductReasonsField
                  v-if="
                    mainData.paso_3_1.justificacion_inadmision.causal_legal
                      .aplica_producto_motivo
                  "
                  :soloPreview="soloPreview"
                  class="mt-10 mb-10 col-start-1 col-span-4"
                  :hideTopInputs="true"
                  :products_reasons.sync="mainData.paso_3_1.productos_motivos"
                  :hideInstanceCounter="true"
                  label-product-counter="Número de solicitudes de conciliación recibidas en el período según producto seleccionado"
                  label-reasons-counter="Número de solicitudes de conciliación recibidas en el período según motivo seleccionado"
                />
              </div>
            </a-card>
            <a-card
              v-if="isJustInadActive('2')"
              size="small"
              class="text-left col-span-4"
              title="2) Se refiere a otra entidad vigilada"
            >
              <a-button
                slot="extra"
                type="link"
                @click="
                  openAccordions.showJustInad2 = !openAccordions.showJustInad2
                "
              >
                Ver detalles
                <a-icon :type="openAccordions.showJustInad2 ? 'up' : 'down'" />
              </a-button>
              <div v-if="openAccordions.showJustInad2">
                <BaseNumber
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision.otra_entidad
                      .numero_total_solicitudes
                  "
                  :disabled="soloPreview"
                  ref="num_31_numero_total_solicitudes"
                  label="Número total de solicitudes de conciliación inadmitidas asociadas a la justificación seleccionada."
                  class="col-start-1 col-span-2"
                  placeholder="Ej: 123"
                />
              </div>
            </a-card>
            <a-card
              v-if="isJustInadActive('3')"
              size="small"
              class="text-left col-span-4"
              title="3) Solicitud incompleta, no clara, lenguaje soez o agresivo"
            >
              <a-button
                slot="extra"
                type="link"
                @click="
                  openAccordions.showJustInad3 = !openAccordions.showJustInad3
                "
              >
                Ver detalles
                <a-icon :type="openAccordions.showJustInad3 ? 'up' : 'down'" />
              </a-button>
              <div v-if="openAccordions.showJustInad3">
                <BaseNumber
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision
                      .solicitud_incompleta.numero_total_solicitudes
                  "
                  :disabled="soloPreview"
                  ref="num_31_numero_total_solicitudes"
                  label="Número total de solicitudes de conciliación inadmitidas asociadas a la justificación seleccionada."
                  class="col-start-1 col-span-2"
                  placeholder="Ej: 123"
                />
              </div>
            </a-card>
            <a-card
              v-if="isJustInadActive('4')"
              size="small"
              class="text-left col-span-4"
              title="4) Otra"
            >
              <a-button
                slot="extra"
                type="link"
                @click="
                  openAccordions.showJustInad4 = !openAccordions.showJustInad4
                "
              >
                Ver detalles
                <a-icon :type="openAccordions.showJustInad4 ? 'up' : 'down'" />
              </a-button>
              <div v-if="openAccordions.showJustInad4">
                <BaseNumber
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision.otra
                      .numero_total_solicitudes
                  "
                  :disabled="soloPreview"
                  ref="num_31_numero_total_solicitudes"
                  label="Número total de solicitudes de conciliación inadmitidas asociadas a la justificación seleccionada."
                  class="col-start-1 col-span-2"
                  placeholder="Ej: 123"
                />
                <BaseTextarea
                  v-model="
                    mainData.paso_3_1.justificacion_inadmision.otra.descripcion
                  "
                  :disabled="soloPreview"
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Resumen general de la situación presentada."
                  placeholder="Escribe la descripción..."
                  maxlength="50"
                />
              </div>
            </a-card>

            <BaseTextarea
              v-model="mainData.paso_3_1.descripcion"
              :disabled="soloPreview"
              class="col-start-1 col-span-4"
              ref="describe"
              id="describe"
              label="Informe brevemente y de manera consolidada las principales temáticas de las solicitudes de conciliación que fueron inadmitidas por la DCF. (Por favor no incluir casos individuales)"
              placeholder="Escribe la descripción..."
              maxlength="1000"
            />
          </div>
        </Accordion>

        <!--  **************************************  START STEP 3.2  *********************************************  -->
        <Accordion
          v-if="mainData.dcf_recibio_solicitudes"
          :open="openAccordions.acc_32"
          :disable="disableAccordions.disable_32"
          key="astep2"
          class="px-0 col-start-1 col-span-4"
          header="3.2 Solicitudes en trámite"
        >
          <h2 class="my-5 text-lg text-left col-span-2">
            <b>3.2. Solicitudes admitidas</b>
          </h2>

          <BaseRadio
            v-model="mainData.paso_3_2.dcf_recibio_solicitudes"
            :disabled="soloPreview"
            ref="opt_paso_3_2_dcf_recibio_solicitudes"
            name="opt_paso_3_2_dcf_recibio_solicitudes"
            label="¿La DCF admitió solicitudes de conciliación en el período reportado?"
            :radioOptions="options.oYesOrNot"
            required="true"
            class="mb-8"
          />

          <div
            v-if="mainData.paso_3_2.dcf_recibio_solicitudes"
            class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
          >
            <ProductReasonsField
              :soloPreview="soloPreview"
              :uniqueIdentity="'paso_3_2'"
              class="mt-10 mb-10 col-start-1 col-span-4"
              :total-counter.sync="mainData.paso_3_2.numero_total_solicitudes"
              :products_reasons.sync="mainData.paso_3_2.productos_motivos"
              :hideInstanceCounter="true"
              label-product-counter="Número de solicitudes de conciliación admitidas en el período, según el producto seleccionado"
              label-reasons-counter="Número de solicitudes de conciliación admitidas en el período, según el motivo seleccionado"
              label-total-counter="Número total de solicitudes de conciliación admitidas en el período"
            />

            <BaseTextarea
              v-model="mainData.paso_3_2.descripcion"
              :disabled="soloPreview"
              class="col-start-1 col-span-4"
              ref="describe"
              id="describe"
              label="Informe brevemente y de manera consolidada las principales temáticas de las solicitudes de conciliación que fueron admitidas por la DCF. (Por favor no incluir casos individuales)"
              placeholder="Mensaje"
              maxlength="1000"
            />

            <!--  **************************************  START STEP 3.2.1  *********************************************  -->
            <Accordion
              :open="openAccordions.acc_32"
              key="astep321"
              class="px-0 col-start-1 col-span-4"
              header="3.2.1  Formas de terminación de los trámites admitidos"
            >
              <!--  **************************************  START STEP 3.2.1.1  *********************************************  -->
              <h2 class="my-5 text-xl text-left col-span-2">
                <b
                  >3.2.1.1 Trámites de conciliación terminados por acuerdo
                  total</b
                >
              </h2>

              <BaseRadio
                v-model="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_1.dcf_termino_tramites
                "
                :disabled="soloPreview"
                ref="opt_paso_3_2_1_1_dcf_recibio_solicitudes"
                name="opt_paso_3_2_1_1_dcf_recibio_solicitudes"
                label="¿La DCF terminó trámites de conciliación con suscripción de acuerdo total entre las partes en el período reportado?"
                :radioOptions="options.oYesOrNot"
                required="true"
                class="mb-8"
              />

              <div
                v-if="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_1.dcf_termino_tramites
                "
                class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
              >
                <ProductReasonsField
                  :soloPreview="soloPreview"
                  class="mt-10 mb-10 col-start-1 col-span-4"
                  :total-counter.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_1
                      .numero_total_tramites
                  "
                  :products_reasons.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_1.productos_motivos
                  "
                  :hideInstanceCounter="true"
                  label-product-counter="Número de trámites de conciliación terminados por acuerdo total en el período, según el producto seleccionado"
                  label-reasons-counter="Número de trámites de conciliación terminados por acuerdo total en el período, según el motivo seleccionado"
                  label-total-counter="Número total de trámites de conciliación terminados por acuerdo total en el período"
                />

                <BaseTextarea
                  v-model="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_1.descripcion
                  "
                  :disabled="soloPreview"
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Informe brevemente y de manera consolidada las principales temáticas de las conciliaciones que fueron cerradas por acuerdo total. (Por favor no incluir casos individuales)"
                  placeholder="Mensaje"
                  maxlength="1000"
                />
              </div>
              <!--  ***********************************************************************************  -->

              <!--  **************************************  START STEP 3.2.1.2  *********************************************  -->
              <h2 class="my-5 text-xl text-left col-span-2">
                <b
                  >3.2.1.2 Trámites de conciliación terminados por acuerdo
                  parcial</b
                >
              </h2>

              <BaseRadio
                v-model="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_2.dcf_termino_tramites
                "
                :disabled="soloPreview"
                ref="opt_paso_3_2_1_2_dcf_recibio_solicitudes"
                name="opt_paso_3_2_1_2_dcf_recibio_solicitudes"
                label="¿La DCF terminó trámites de conciliación con suscripción de acuerdo parcial entre las partes en el período reportado?"
                :radioOptions="options.oYesOrNot"
                required="true"
                class="mb-8"
              />

              <div
                v-if="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_2.dcf_termino_tramites
                "
                class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
              >
                <ProductReasonsField
                  class="mt-10 mb-10 col-start-1 col-span-4"
                  :soloPreview="soloPreview"
                  :total-counter.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_2
                      .numero_total_tramites
                  "
                  :products_reasons.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_2.productos_motivos
                  "
                  :hideInstanceCounter="true"
                  label-product-counter="Número de  trámites de conciliación terminados por acuerdo parcial en el período, según el producto seleccionado"
                  label-reasons-counter="Número de  trámites de conciliación terminados por acuerdo parcial en el período, según el motivo seleccionado"
                  label-total-counter="Número total de trámites de conciliación terminados por acuerdo parcial en el período"
                />

                <BaseTextarea
                  v-model="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_2.descripcion
                  "
                  :disabled="soloPreview"
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Informe brevemente y de manera consolidada las principales temáticas de las conciliaciones que fueron cerradas por acuerdo parcial. (Por favor no incluir casos individuales)"
                  placeholder="Mensaje"
                  maxlength="1000"
                />
              </div>
              <!--  ***********************************************************************************  -->

              <!--  **************************************  START STEP 3.2.1.3  *********************************************  -->
              <h2 class="my-5 text-xl text-left col-span-2">
                <b
                  >3.2.1.3 Trámites de conciliación terminados con constancia de
                  no acuerdo</b
                >
              </h2>

              <BaseRadio
                v-model="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_3.dcf_termino_tramites
                "
                :disabled="soloPreview"
                ref="opt_paso_3_2_1_3_dcf_recibio_solicitudes"
                name="opt_paso_3_2_1_3_dcf_recibio_solicitudes"
                label="¿La DCF terminó trámites de conciliación con constancia de no acuerdo en el período reportado?"
                :radioOptions="options.oYesOrNot"
                required="true"
                class="mb-8"
              />

              <div
                v-if="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_3.dcf_termino_tramites
                "
                class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
              >
                <ProductReasonsField
                  class="mt-10 mb-10 col-start-1 col-span-4"
                  :soloPreview="soloPreview"
                  :total-counter.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_3
                      .numero_total_tramites
                  "
                  :products_reasons.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_3.productos_motivos
                  "
                  :hideInstanceCounter="true"
                  label-product-counter="Número de  trámites de conciliación terminados con constancia de no acuerdo en el período, según el producto seleccionado"
                  label-reasons-counter="Número de  trámites de conciliación terminados con constancia de no acuerdo en el período, según el motivo seleccionado"
                  label-total-counter="Número total de trámites de conciliación terminados con constancia de no acuerdo en el período"
                />

                <BaseTextarea
                  v-model="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_3.descripcion
                  "
                  :disabled="soloPreview"
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Informe brevemente y de manera consolidada las principales temáticas de las conciliaciones que fueron cerradas por constancia de no acuerdo. (Por favor no incluir casos individuales)"
                  placeholder="Mensaje"
                  maxlength="1000"
                />
              </div>
              <!--  ***********************************************************************************  -->

              <!--  **************************************  START STEP 3.2.1.4  *********************************************  -->
              <h2 class="my-5 text-xl text-left col-span-2">
                <b
                  >3.2.1.4 Trámites de conciliación terminados por
                  desistimiento</b
                >
              </h2>

              <BaseRadio
                v-model="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_4.dcf_termino_tramites
                "
                :disabled="soloPreview"
                ref="opt_paso_3_2_1_4_dcf_recibio_solicitudes"
                name="opt_paso_3_2_1_4_dcf_recibio_solicitudes"
                label="¿La DCF terminó trámites de conciliación por desistimiento entre las partes en el período reportado?"
                :radioOptions="options.oYesOrNot"
                required="true"
                class="mb-8"
              />

              <div
                v-if="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_4.dcf_termino_tramites
                "
                class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
              >
                <ProductReasonsField
                  class="mt-10 mb-10 col-start-1 col-span-4"
                  :soloPreview="soloPreview"
                  :total-counter.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_4
                      .numero_total_tramite
                  "
                  :products_reasons.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_4.productos_motivos
                  "
                  :hideInstanceCounter="true"
                  label-product-counter="Número de  trámites de conciliación terminados por desistimiento en el período, según el producto seleccionado"
                  label-reasons-counter="Número de  trámites de conciliación terminados por desistimiento en el período, según el motivo seleccionado"
                  label-total-counter="Número total de trámites de conciliación  terminados por desistimiento en el período"
                />

                <BaseTextarea
                  v-model="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_4.descripcion
                  "
                  :disabled="soloPreview"
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Informe brevemente y de manera consolidada las principales temáticas de las conciliaciones que fueron cerradas  por desistimiento. (Por favor no incluir casos individuales)"
                  placeholder="Mensaje"
                  maxlength="1000"
                />
              </div>
              <!--  ***********************************************************************************  -->

              <!--  **************************************  START STEP 3.2.1.5  *********************************************  -->
              <h2 class="my-5 text-xl text-left col-span-2">
                <b
                  >3.2.1.5 Trámites de conciliación terminados con constancia de
                  no asistencia</b
                >
              </h2>

              <BaseRadio
                v-model="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.dcf_termino_tramites
                "
                :disabled="soloPreview"
                ref="opt_paso_3_2_1_5_dcf_recibio_solicitudes"
                name="opt_paso_3_2_1_5_dcf_recibio_solicitudes"
                label="¿La DCF adelantó trámites de conciliación con constancia de no asistencia?"
                :radioOptions="options.oYesOrNot"
                required="true"
                class="mb-8"
              />

              <BaseSelect
                :disabled="soloPreview"
                v-model="mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.no_asistio"
                @change="onChangeSelectNoAsistio"
                :getObjectValue="true"
                ref="opt_infraestructura"
                class="col-start-1 col-span-2"
                label="¿Quien no asistió fue el consumidor financiero o la entidad vigilada o ambas partes?"
                :selectOptions="options.oNoAsistio"
              />

              <div
                v-if="
                  mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.dcf_termino_tramites
                "
                class="w-full grid grid grid-cols-4 gap-x-4 gap-y-8 justify-end items-end pt-0 mt-0 mb-4"
              >
                <ProductReasonsField
                  class="mt-10 mb-10 col-start-1 col-span-4"
                  :soloPreview="soloPreview"
                  :total-counter.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5
                      .numero_total_tramites
                  "
                  :products_reasons.sync="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.productos_motivos
                  "
                  :hideInstanceCounter="true"
                  label-product-counter="Número de trámites de conciliación con constancia de no asistencia en el período, según el producto seleccionado"
                  label-reasons-counter="Número de trámites de conciliación con constancia de no asistencia en el período, según el motivo seleccionado"
                  label-total-counter="Número total de trámites de conciliación con constancia de no asistencia en el período"
                />

                <BaseTextarea
                  v-model="
                    mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.descripcion
                  "
                  :disabled="soloPreview"
                  class="col-start-1 col-span-4"
                  ref="describe"
                  id="describe"
                  label="Informe brevemente y de manera consolidada las principales temáticas de las conciliaciones que fueron cerradas por constancia de no asistencia. (Por favor no incluir casos individuales)"
                  placeholder="Mensaje"
                  maxlength="1000"
                />
              </div>
              <!--  ***********************************************************************************  -->
            </Accordion>
          </div>
        </Accordion>

        <!--  **************************************  START STEP 3.3  *********************************************  -->
        <Accordion
          v-if="mainData.dcf_recibio_solicitudes"
          :open="openAccordions.acc_33"
          :disable="disableAccordions.disable_33"
          key="astep3"
          class="px-0 col-start-1 col-span-4"
          header="3.3 Casos relevantes en desarrollo de la función de conciliación"
        >
          <BaseRadio
            v-model="mainData.paso_3_3.dcf_indentifica_casos"
            :disabled="soloPreview"
            ref="opt_dcf_indentifica_casos"
            name="opt_dcf_indentifica_casos"
            label="¿La DCF puede identificar casos relevantes en desarrollo de la función de conciliación, que considere de interés compartir? (En el período reportado)"
            :radioOptions="options.oYesOrNot"
            required="true"
            class="mb-8"
          />

          <BaseTextarea
            v-if="mainData.paso_3_3.dcf_indentifica_casos"
            v-model="mainData.paso_3_3.descripcion"
            :disabled="soloPreview"
            class="col-start-1 col-span-4"
            ref="describe"
            id="describe"
            label="Relacione y describa brevemente los casos relevantes que el DCF, a su juicio, considere de interés. (Por favor omita nombre y datos identificatorios del consumidor financiero para garantizar la reserva de los mismos. Tampoco realice transcripciones textuales de las respectivas decisiones.)"
            placeholder="Mensaje"
            maxlength="1000"
          />
        </Accordion>

        <!--  **************************************  START STEP 3.4  *********************************************  -->
        <Accordion
          v-if="mainData.dcf_recibio_solicitudes"
          :open="openAccordions.acc_34"
          :disable="disableAccordions.disable_34"
          key="astep4"
          class="px-0 col-start-1 col-span-4"
          header="3.4 Documentos relacionados con la función de conciliación"
        >
          <p
            class="w-full mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
          >
            <b
              >Cargue únicamente los documentos relacionados con la función de
              conciliación que considere necesarios para complementar la
              información suministrada sobe dicha función (Por favor no adjuntar
              actas de audiencias de conciliación ni de constancias de
              terminación. Omita nombre y datos identificatorios del consumidor
              financiero para garantizar la reserva de los mismos. Tampoco
              realice transcripciones textuales de las respectivas
              decisiones.)</b
            >
          </p>

          <!-- <pre>{{
            JSON.stringify(mainData.paso_3_4.archivos_soporte, null, 4)
          }}</pre> -->
          <manage_files
            @files-changed="handleFilesChange"
            :hideDragDrop="soloPreview"
            :filesList="mainData.paso_3_4.archivos_soporte"
            :dragDropTitle="'Límite nro. de archivos de carga: Máximo 5 archivos.\n Límite de peso total por carga: 5MB total de archivos subidos (Máximo 1MB por archivo cargado)'"
            :extensions="[
              '.pdf',
              '.jpg',
              '.jpeg',
              '.png',
              '.doc',
              '.docx',
              '.xls',
              '.xlsx',
            ]"
            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          ></manage_files>
        </Accordion>
      </div>
    </Accordion>
  </div>
</template>


<script>
import ErrorMessaje from "@/core/error/error_messaje";
import AnnualReportServices from "@/feature/annual_report/data/services/annual_report_services";
import manage_files from "@/feature/annual_report/presentation/views/components/manage_files.vue";
import TemplateProductInputs from "@/feature/annual_report/presentation/views/components/template_product_inputs.vue";
import { get, has, uniqueId, set, debounce, isEmpty, isEqual } from "lodash";
import ProductReasonsField from "@/feature/annual_report_v2/presentation/components/ProductReasonsField";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";

const columns = [
  {
    title: "",
    dataIndex: "labelName",
    scopedSlots: { customRender: "labelName" },
  },
  {
    title: "",
    dataIndex: "count",
    scopedSlots: { customRender: "count" },
  },
];

export default {
  components: {
    Accordion,
    manage_files,
    TemplateProductInputs,
    ProductReasonsField,
  },
  props: {
    initialData: {
      type: Object,
      default: null,
    },
    soloPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: columns,
      prueba: null,
      isFisrtTime: true,
      hasChanged: false,
      valueCheck: null,
      mainData: {
        descripcion: "", // str:300
        optInfraestructura: "",
        optInfraestructuraDetail: "", // options.oInfra
        dcf_recibio_solicitudes: null, // boolean
        numero_total_solicitudes: null, // Number
        productos_motivos: [],
        paso_3_1: {
          dcf_inadmitio_solicitudes: null,
          numero_total_solicitudes: null, // Number
          justificacion_inadmision: {
            ids: [], // array "1, 2, 3 ó 4"
            justificacion_inadmision_detail: {}, // object
            causal_legal: {
              aplica_producto_motivo: false,
              numero_total_solicitudes: null, // Number
              tipo_justificacion: [],
              tipo_justificacion_detail: [],
              otra_descripcion: "",
            },
            otra_entidad: {
              numero_total_solicitudes: null,
            },
            solicitud_incompleta: {
              numero_total_solicitudes: null,
            },
            otra: {
              numero_total_solicitudes: null,
              descripcion: "", // str:50
            },
          },
          numero_total_solicitudes_recibidas: null, // Number
          productos_motivos: [],
          descripcion: "", // str: 1000
        },
        paso_3_2: {
          dcf_recibio_solicitudes: null,
          numero_total_solicitudes: null,
          productos_motivos: [],
          descripcion: "", // str: 1000
          paso_3_2_1: {
            paso_3_2_1_1: {
              dcf_termino_tramites: null, // Boolean
              numero_total_tramites: null, // Number
              productos_motivos: [],
              descripcion: "", // str: 1000
            },
            paso_3_2_1_2: {
              dcf_termino_tramites: null, // Boolean
              numero_total_tramites: null, // Number
              productos_motivos: [],
              descripcion: "", // str: 1000
            },
            paso_3_2_1_3: {
              dcf_termino_tramites: null, // Boolean
              numero_total_tramites: null, // Number
              productos_motivos: [],
              descripcion: "", // str: 1000
            },
            paso_3_2_1_4: {
              dcf_termino_tramites: null, // Boolean
              numero_total_tramites: null, // Number
              productos_motivos: [],
              descripcion: "", // str: 1000
            },
            paso_3_2_1_5: {
              dcf_termino_tramites: null, // Boolean
              no_asistio: "", // "1, 2 ó 3"
              no_asistio_detail: "",
              numero_total_tramites: null, // Number
              productos_motivos: [],
              descripcion: "", // str: 1000
            },
          },
        },
        paso_3_3: {
          dcf_indentifica_casos: null, // boolean
          descripcion: "", // str: 1000
        },
        paso_3_4: {
          archivos_soporte: [], //
        },
      },
      feedback: "",
      files: {
        conciliation: [],
      },
      openAccordions: {
        acc_3: true,
        acc_31: false,
        acc_32: false,
        acc_33: false,
        acc_34: false,
        showJustInad1: false,
        showJustInad2: false,
        showJustInad3: false,
        showJustInad4: false,
      },
      disableAccordions: {
        disable_31: false,
        disable_32: false,
        disable_33: false,
        disable_34: false,
      },
      options: {
        oYesOrNot: [
          { id: true, name: "Sí" },
          { id: false, name: "No" },
        ],
        oInfra: [
          { id: "1", name: "1. Propia de la DCF" },
          {
            id: "2",
            name: "2. Por convenio con un tercero o centro de conciliación",
          },
        ],
        oNoAsistio: [
          { id: "1", name: "1. No asistió el Consumidor Financiero." },
          { id: "2", name: "2. No asistió la Entidad Vigilada (EV)." },
          { id: "3", name: "3. Ninguna de las partes asistió." },
        ],
        oApplyProductReason: [
          { id: true, name: "Aplica" },
          { id: false, name: "No aplica" },
        ],
        oJustInadmision: [
          { id: "1", labelName: "1) Causal legal de exclusión de competencia" },
          { id: "2", labelName: "2) Se refiere a otra entidad vigilada" },
          {
            id: "3",
            labelName:
              "3) Solicitud incompleta, no clara, lenguaje soez o agresivo",
          },
          { id: "4", labelName: "4) Otra" },
        ],
        oTipoJustInCausaLegal: [
          {
            id: "1",
            labelName:
              "a. No corresponde al giro ordinario de las operaciones de la entidad",
          },
          {
            id: "2",
            labelName:
              "b. Se refiere al vínculo laboral entre la entidad y sus empleados o contratistas",
          },
          {
            id: "3",
            labelName: "c. Se deriva de condición de accionista de la entidad",
          },
          {
            id: "4",
            labelName:
              "d. Se refiere al reconocimiento de las prestaciones y de las pensiones de invalidez, de vejez y de sobrevivientes",
          },
          {
            id: "5",
            labelName:
              "e. Se encuentra en trámite judicial o arbitral o hayan sido resueltas en estas vías",
          },
          {
            id: "6",
            labelName:
              "f. Corresponde a la decisión sobre la prestación de un servicio o producto",
          },
          {
            id: "7",
            labelName:
              "g. Se refieren a hechos sucedidos con tres (3) años o más de anterioridad",
          },
          {
            id: "8",
            labelName:
              "h. Han sido objeto de decisión previa por parte del Defensor por tener mismos hechos y afectar a las mismas partes",
          },
          { id: "9", labelName: "i. Cuantía supera los cien (100) SMLMV" },
          { id: "10", labelName: "j. Otra" },
        ],
      },
    };
  },
  computed: {
    totalCausales() {
      return this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail.reduce(
        (sum, item) => {
          return sum + Number(item.count);
        },
        0
      );
    },
    rowSelection() {
      const selectedRowKeys = this.options.oTipoJustInCausaLegal
        .map((objeto, index) =>
          this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion.includes(
            objeto.id
          )
            ? index
            : null
        )
        .filter((index) => index !== null);
      return {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion =
            [];
          selectedRows.forEach((element) => {
            this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion.push(
              element.id
            );
          });
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );

          const updatedDetail = [];

          this.options.oTipoJustInCausaLegal.forEach((element) => {
            if (
              this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion.includes(
                element.id
              )
            ) {
              const justDetail =
                this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail.find(
                  (detail) => detail.id === element.id
                );
              console.log("count, count, count", element.id, justDetail);
              updatedDetail.push({
                id: element.id,
                labelName: element.labelName,
                count: justDetail?.count ?? null,
              });
            }
          });

          this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail =
            updatedDetail;

          console.log(
            "this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion",
            this.mainData.paso_3_1.justificacion_inadmision.causal_legal
              .tipo_justificacion
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.soloPreview,
            name: record.name,
            defaultChecked: record.isChecked ?? false,
          },
        }),
      };
    },
  },
  watch: {
    "mainData.dcf_recibio_solicitudes": function () {
      Object.keys(this.disableAccordions).forEach((clave) => {
        this.disableAccordions[clave] = !this.mainData.dcf_recibio_solicitudes;
      });
    },
    initialData: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null) {
          this.mainData = newVal;
          if (!this.isFisrtTime && !this.hasChanged) {
            this.hasChanged = true;
            console.log("mainData 3 hasChanged:", this.hasChanged);
          }
          this.isFisrtTime = false;
        }
      },
    },
  },
  created() {
    if (this.initialData == null) {
      this.hasChanged = true;
    }
    this.mainData = this.initialData ?? this.mainData;
  },
  methods: {
    handleFilesChange(selectedFiles) {
      this.files.conciliation = selectedFiles;
      console.log("recibiendo archivos", this.files.conciliation);
    },
    isRowSelected(key) {
      return this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion.includes(
        key
      );
    },
    valueCausal(id) {
      const item =
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail.find(
          (element) => element.id === id
        );

      if (item) {
        return item.count;
      } else {
        console.error("No se encontró un elemento con el id:", id);
        return null;
      }
    },
    updateDetail(key, value) {
      console.log(key, value);
      console.log(
        "ttipo_justificacion_detail",
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal
          .tipo_justificacion_detail
      );
      const detailIndex =
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail.findIndex(
          (detail) => detail.id === key
        );
      if (detailIndex >= 0) {
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail[
          detailIndex
        ].count = value;
        console.log(
          "this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail",
          this.mainData.paso_3_1.justificacion_inadmision.causal_legal
            .tipo_justificacion_detail
        );
      } else {
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail.push(
          { id: key, count: value }
        );
      }
    },
    async updateFiles(documentId) {
      let error = false;
      if (this.files.conciliation.length > 0) {
        const formData = new FormData();
        formData.append("document_id", documentId);
        formData.append("nested_path", "paso_3_4.archivos_soporte");
        formData.append("step", 3);
        for (const element of this.files.conciliation) {
          formData.append("files", element);
        }
        for (let [key, value] of formData.entries()) {
          console.log(
            `FD -> ${key}:`,
            value instanceof File ? value.name : value
          );
        }
        this.$message.loading({
          content: "Subiendo archivos...",
          keyMessage: "fileMessage",
          duration: 10,
        });
        let response = await AnnualReportServices.updateAnualReportFiles(
          formData
        );
        console.log("File response", response);
        this.$message.destroy();
        if (!response.isSuccess) {
          this.$notification.error({
            message: ErrorMessaje.errorTitle,
            description: response.message,
          });
          error = true;
        } else {
          this.mainData.paso_3_4.archivos_soporte = response.data.files_saved;
          console.log(
            "this.mainData.paso_3_4.archivos_soporte",
            this.mainData.paso_3_4.archivos_soporte
          );
        }
      }
      return error;
    },
    notifyChanges() {
      let error = false;
      // Se implementa lógica antes de guardar la info, en caso de ser necesario
      console.log("notifyChanges", this.mainData);
      return { error: error, data: this.mainData };
    },
    onChangeSelectInfra(value) {
      console.log(value);
      this.mainData.optInfraestructura = value.id;
      this.mainData.optInfraestructuraDetail = value.name;
    },
    onChangeSelectNoAsistio(value) {
      console.log(value);
      this.mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.no_asistio = value.id;
      this.mainData.paso_3_2.paso_3_2_1.paso_3_2_1_5.no_asistio_detail =
        value.name;
    },
    isJustInadActive(value) {
      return this.mainData.paso_3_1.justificacion_inadmision.ids.includes(
        value
      );
    },
    causalLegalIncludeOtra() {
      const item =
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail.findIndex(
          (detail) => detail.id == "10"
        );
      return item >= 0;
    },
    onChangeSelectTipoJustInCausaLegal(values) {
      let data = values.map((val) => val.id);

      if (!isEqual(data, this.mainData.paso_3_1.justificacion_inadmision.ids)) {
        values.forEach((element) => {
          this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion_detail[
            element.id
          ] = element.labelName;
        });
        this.mainData.paso_3_1.justificacion_inadmision.causal_legal.tipo_justificacion =
          data;
        console.log(
          "onChangeSelectTipoJustInCausaLegal",
          this.mainData.paso_3_1.justificacion_inadmision.causal_legal
            .tipo_justificacion
        );
      }
    },
    onChangeSelectJustInadmision(values) {
      if (
        !isEqual(values, this.mainData.paso_3_1.justificacion_inadmision.ids)
      ) {
        this.mainData.paso_3_1.justificacion_inadmision.justificacion_inadmision_detail =
          [];
        this.options.oJustInadmision.forEach((element) => {
          if (values.includes(element.id)) {
            this.mainData.paso_3_1.justificacion_inadmision.justificacion_inadmision_detail[
              element.id
            ] = element.labelName;
          }
        });
        this.mainData.paso_3_1.justificacion_inadmision.ids = values;
      }
    },
    getProductReasons(value) {
      console.log("Value of products", value);
      if (value.clave == "step_3") {
        delete value.clave;
        this.mainData.productos_motivos = [...value];
      }
      if (value.clave == "step_3_1_causa") {
        delete value.clave;
        this.mainData.paso_3_1.productos_motivos = [...value];
      }
    },
  },
};
</script>