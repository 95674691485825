<template>
    <div class="w-full mt-10 mb-10">

        <Accordion :open="open" :disable="!open" key="step-1.1"
            header="1.1 Reporte estadístico de quejas recibidas por parte del DCF">


            <ProductReasonField :labelProductCounter="'Número de quejas recibidas sobre producto seleccionado'"
                :labelReasonsCounter="'Número de quejas recibidas sobre motivo seleccionado'"
                :labelTotalCounter="'Número total de quejas recibidas por la DCF'"
                :urlGetCounter="'?insta_recepcion_id=3'"
                :labelInstanceCounter="'Número de quejas registradas instancia DCF'"
                :total-counter.sync="mainData.step_1_1.total_quejas_recibidas_DCF"
                :products_reasons.sync="mainData.step_1_1.producto_motivo" :soloPreview="soloPreview" />

        </Accordion>
    </div>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import TemplateProductInputs from "../template_product_inputs.vue";
import ProductReasonField from "../../../../../annual_report_v2/presentation/components/ProductReasonsField";


export default {
    components: {
        TemplateProductInputs, Accordion, ProductReasonField
    },
    props: {
        soloPreview: {
            type: Boolean,
            default: false
        },
        mainData: {

        },
        open: {
            type: Boolean,
            default: false
        }
    },
}

</script>